<template>
  <div>
    <label class="font-bold  my-2 inline-block text-uppercase">
      {{ label }}
    </label>
    <SelectComponent :items="items" placeholder="All" border-none class="bg-periwinkle-gray w-full rounded" />
  </div>
</template>

<script>
import SelectComponent from "@/components/atom/form/SelectComponent";
export default {
    props: {
        label: {
            type: String
        },
        items: {
          type: Array,
          default: () => false
        }
    },
    components: {
        SelectComponent
    }
};
</script>

<style></style>
