<template>
  <div class="md-layout md-gutter mt-6">
        <div class="md-layout-item md-xsmall-size-100 md-medium-size-40 md-large-size-15">
          <SelectFilter :items="sources" label="Source" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-medium-size-30 md-large-size-15">
          <SelectFilter :items="frequency" label="Frequency" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-medium-size-30 md-large-size-15">
          <SelectFilter :items="statuses" label="Status" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-medium-size-40 md-large-size-25">
          <SelectFilter :items="sampleAndExplanation" label="Sample & Explaination" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-medium-size-30 md-large-size-15">
          <SelectFilter :items="verified" label="Verified" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-medium-size-30 md-large-size-15">
          <SelectFilter :items="questionTypes" label="Question Type" />
        </div>
    </div>
</template>

<script>
import SelectFilter from "@/components/molecule/test-creation/SelectFilter";
import question from "@/data/question-creation/data";
import cms from "@/data/cms";
export default {
    components: {
        SelectFilter
    },
    data() {
        return {
            sources: cms.source,
            frequency: cms.frequency,
            statuses: cms.statuses,
            verified: cms.verified,
            sampleAndExplanation: cms.sampleAndExplanation,
            questionTypes: question.questionTypes,
        }
    }
}
</script>