<template>
  <div>
    <div class="bg-link-water p-4 rounded-lg">
      <div class="md-layout md-gutter py-2">
        <div class="md-layout-item flex align-center">
          <md-icon class="m-0">menu_book</md-icon>
          <span class="text-uppercase font-bold text-lg ml-2">Reading</span>
          <span class="text-uppercase ml-2">( Total 125 Question )</span>
        </div>
      </div>
      <PracticeTestListingFilter />
    </div>
    <div>
      <Table :items="questions" @onSelect="onSelect">
      <template
        slot="row"
        slot-scope="{ item }"
        md-selectable="single"
      >
        <md-table-cell md-numeric>{{ item.id }}</md-table-cell>
        <md-table-cell>{{ item.code }}</md-table-cell>
        <md-table-cell>{{ item.title }}</md-table-cell>
        <md-table-cell>
          <span class="p-1 rounded text-base bg-victoria text-white">RO-5</span>
          <span class="p-1 rounded text-base ml-1 bg-victoria text-white"
            >S-7</span
          >
          <span class="p-1 rounded text-base ml-1 bg-victoria text-white"
            >RS-3</span
          >
          <span class="p-1 rounded text-base ml-1 bg-victoria text-white"
            >MCQSA-12</span
          >
        </md-table-cell>
        <md-table-cell>
          <md-icon class="p-4 text-3xl outline-gray-400 rounded"
            >turned_in</md-icon
          >
        </md-table-cell>
      </template>
    </Table>
    <div class="flex justify-between align-center my-6">
        <md-button class="bg-victoria text-white text-italic rounded ml-0">Back</md-button>
        <Paginate
        :start="1"
        :end="20"
        :total="100"
        :limit="20"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Table, Paginate } from "@/components";
import PracticeTestListingFilter from "@/components/molecule/test-creation/PracticeTestListingFilter"
import data from '@/data/pte-practice/data'
export default {
  components: {
    Table,
    Paginate,
    PracticeTestListingFilter
  },
  data() {
    return {
      questions: data.questions
    }
  },
  methods: {
    onSelect(item) {
      this.$router.push({
        name: "student.ptePractice.question",
        params: {
          slug: this.$route.params.slug,
          pteId: this.$route.params.pteId,
          questionId: item.id,
        },
      });
    },
  }
};
</script>
